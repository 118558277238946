export const DATA = [
  {
    id: 1,
    year: 2022,
    title: 'ECI AWARDS 2022 is looking for INNOVATIVE PEOPLE OF THE YEAR',
    desc: `Innovative Leaders of The Year 2022
    <br>Innovative Influentials of The Year 2022
    <br>Innovative Marketers of The Year 2022
    <br>Innovative Pioneers of The Year 2022
    <br>Innovative COI of The Year 2022
    <br>Outstanding Women Leaders in Digital Innovation of The Year 2022`,
    img: ['sucai/aUp/2022b.jpg'],
    titleimg: 'sucai/aUp/2022.jpg',
    time: '',
  },
  {
    id: 2,
    year: 2020,
    title: '2020 ECI Awards Judges Outlook of Innovation',
    desc: ``,
    img: ['sucai/aUp/2020b.jpg'],
    titleimg: 'sucai/aUp/2020.jpg',
    time: '',
  },
  {
    id: 3,
    year: 2020,
    title: 'The New Normal of Digital Economy After COVID',
    desc: `About ECI Awards:
    <br>Honoring the innovation that defines future!
    <br>ECI Awards, founded by IECIA (International Entrepreneurs, Creatives & Innovators Association) in the United States in 2014, is the first global award in the digital economy that uses "effectiveness of innovation" as its evaluation criteria.
    <br>It recognizes the most innovative achievements in the categories of business model, product, service, technology, marketing and management, as well as individuals and organizations, to encourage and inspire innovation applied in commercial and creative industries around the world.
    <br>IECIA has established partnerships with 23 countries and regions in the world, including the United States, the United Kingdom, Japan, Germany, France, Singapore, India and so on.
    <br>Over 1000 CEOs of innovative enterprises, over 2000 high-level experts in the fields of marketing, technology and investment as well as more than 200 listed internet companies have participated in various ECI Awards events.  Currently, ECI Awards is working closely with more than 10,000 business innovation projects and more than 300 innovation industrial parks. Over 300 media have covered its programs and activities. 
    <br>Over the past few years, ECI Awards has given out Innovative Leaders of the Year Award to Travis Kalanick, Uber’s co-founder and former CEO, Jun Lei, co-founder and chairman of Xiaomi,  as well as Franklin Urteaga, former innovation advisor to the White House and co-founder of OIGETIT Instant News, among others. 
    <br>At ECI Awards 2019, we invited industry experts from different countries and regions, including Donna Smith, the United States Film Ambassador to China, Marilyn Librers, President of China Silicon Valley and George Gilder, American investor, writer, economist and co-founder of the Discovery Institute as the VIP guests. 
    
    <br><br>
    Benefits to ECI Awards Participants:
    <br>1）Brand Value:
    <br>To improve your brand favorability in the industry and among the consumers with the label of innovation.  
    <br>2）Media Value: 
    <br>To increase your brand awareness through ECI Awards self-media, mainstream media as well as the extensive network of relationships with the ECI Awards experts and judges.
    <br>3）Marketing Value: 
    <br>To attract more clients and partners by taking the opportunity of award winning as an event marketing strategy.
    <br><br>
    4）Investment Value:
    <br>To win the attention from individual and institutional investors and bring about better innovation assessment results.
    <br>5）Network Value:
    <br>To get connected with the experts, judges, speakers and international guests from the ECI Awards platform.
    <br>6）Empowerment Value:
    <br>To get empowered from thousands of winning cases of ECI Awards, innovative ideas at ECI Forums, training courses offered by ECI Academy and the reports on industry trends in ECI Festival.
    
    <br><br>
   
    Eligibility and Entry Categories:
    <br>All innovation efforts in digital business that were operating between January 1, 2019 and May 1, 2020. The entry could be a completed work, an ongoing work or a business plan. The enterprise can be brick-and-mortar establishment using digital means or by internet enterprises.
    <br><br>
    Participants can submit their entries in the following categories:
    <br>a) Model Innovation;
    <br>b) Product Innovation;
    <br>c) Marketing Innovation:
    <br>d) Specialty Category1 – AI Innovation;
    <br>e) Specialty Category2 – Management Innovation;
    <br>*Specialty 2020 – Public Interest Innovation.
    
    <br><br>
    Competition Calendar: 
    <br>Deadlines and Fees:
    <br>Early-bird Deadline: July 31, 2020 / $ 250 USD per entry
    <br>Final Deadline: August 31, 2020 / $ 400 USD per entry
    <br><br>
    Important Dates:
    <br>Final Judging: September 2020
    <br>ECI Awards Gala & 2020 ECI Festival: December 2020 in Macau (Tentative Location)
    
    <br><br>
    Partial List of ECI Awards 2020 Participants:
    <br>Brand:
    <br>Adidas, Alibaba Group, BMW China, Cadillac, Chevrolet, China Construction Bank, China Mobile, China Telecom, Country Garden, Gu Jing Gong Liquor, Hisense, Honda, Huawei, Infiniti, JDE, L'Oréal Paris, LUZHOULAOJIAO, Mercedes-Benz China, OLAY, SAIC VOLKSWAGEN, Sephora, Shiseido, Shu Uemura, Samsung, Tencent Music (TME), Toyota, Yili, YumChina, Zespri Kiwifruit
    <br><br>
    Company:
    <br>Baidu Inc., BanmaNetwork Technology Co.,Ltd., Beijing Sohu New Media Information Technology Co., Ltd., Bosim, Dentsu Taiwan Inc., iFORCE Beijing Interactive Co., Ltd., QWOM Digital Technology, Weibo.com, SINA Corporation, Zhihu.com
    <br><br>
    Contact Us:
    <br>Official Website: www.eciawards.org
    <br>
    <br>Inquiries about Competition:
    <br>Name: Owen Jia
    <br>Tel: +1(818)-452-6959
    <br>Email: owen_jia@eciawards.org
    <br>
    <br>Name: Marilyn Librers
    <br>Tel: +1(408)-892-3883
    <br>Email: marilyn@eciawards.org
    <br>
    <br>Name: Yudan Wang
    <br>Tel: +1(626)-803-7649
    <br>Email: international@eciawards.org`,
    img: ['sucai/aUp/2020b_4.jpg'],
    titleimg: 'sucai/aUp/2020_4.jpg',
    time: '',
  },
  {
    id: 4,
    year: 2020,
    title:
      '2020 ECI Global Innovation & Design for “Fight Against Coronavirus”is calling for entry',
    desc: `2020 is destined to be an extraordinary year. The sudden outbreak of the novel coronavirus has affected the entire business sector, and some of the real economy industries that rely heavily on offline have almost fallen into a dilemma. At the same time, the epidemic will force a large number of industries to transform to the digital economy and become more closely linked to the Internet. Some industries affected by the epidemic have brought certain challenges: catering industry, hotel and tourism industry, film and television entertainment industry, transportation industry, real estate and construction industry; other industries have brought certain opportunities: healthcare industry, artificial intelligence industry, AI and urban management industry, transportation integration industry, intelligent manufacturing industry, consumer upgrades and new retail industry, online service industry, online education industry, online games and video industry, and online office software industry. As a new economic form, the digital economy is an important force to promote economic development, enhance efficiency and propel human progress.
    <br>
    Organized by IECIA(International Entrepreneurs, Creatives & Innovators Association) and Global Digital Economy Foundation, Shanghai Jiao Tong University School of Design, and ECI Great Health Innovation Committee, the theme is "Innovation for LOVE!" In 2020, the innovative charity  design campaign of ECI Global “Health Fight Against Epidemic” is calling for entries. Entry categories include: anti-epidemic product design, anti-epidemic drug design, anti-epidemic service design, anti-epidemic creative design, anti-epidemic report design, and anti-epidemic environment design. At present, since the launch of the "Innovation for LOVE!" charity campaign,  it has received the active participation and attention of enterprises, institutions and experts in the global digital economy.
    <br>
    In order to encourage innovative companies and experts around the world to fight against the epidemic, and to honor the innovative anti-epidemic works designed by them, Global Digital Economy Foundation(GEDF), ECI’s innovation partner in the United States, has established ECI Fund to reward individuals or institutions that contribute to human health, innovate for love, and create touching and anti-epidemic works with great social and market value.
    <br>
    Dr. Debby Joe, CEO of GDEF, said, "As the global strategic partner of ECI, GDEF will reward the institutions that have received ECI Grand Awards each year. This year, we will grand a special prize of $1,000,000 to the winners of 2020 ECI Grand Awards for their innovative designs. One of the original aims of our GDEF is to honor, praise and value the efforts of innovators!"
    <br>
    The awarding ceremony of the "ECI Special Charity Awards" will be held in December 2020 at the ECI Innovation Festival during the Expo 2020 Dubai. At that time, let’s witness how “Innovation for Love” charity design campaign transcends science and technology, transcends business, and transcends national boundaries.
    <br>
    Note: Works participating in "Innovation for LOVE!" charity design campaign can be submitted to the "2020 ECI Awards" at the same time, with for major categories: model innovation category, product innovation category, marketing innovation category, special-AI innovation category.
    <br>
    ECI has always been dedicated to discover innovation, honor innovation, promote innovation, and incubate innovation. Our mission is to Bring Innovation to Life.
    <br>
    Specifications of Entries
    <br>Physical samples or product manuals, including but not limited to design schemes (design sketches, presentation of new materials and technology applications, etc.)
    <br>Description of work innovation : please describe the innovative designs according to the 4 aspects of :
    <br>“Challenge / background + objectives”
    <br>“Idea / solution”
    <br>“Execution / execution process”,
    <br>“Results / value effect”
    <br>with clear depictions on themes, novel ideas, and in concise language.
    <br><br>
    Remarks:
    <br>1) The format of submission should be in PPT or video format (less than 3 minutes, and in MP4 format);
    <br>2) Please make content according to 4 aspects / dimensions;
    <br>3) Download the PPT entry template on the cloud disk
    <br>Link: https://pan.baidu.com/s/1U8X3EwDjlo0c7LFC5qCTSg 
    <br>* Copy the link to the WeChat conversation to open or use a computer to download.  Extraction code: d7xk
    <br><br>
    Submission is free, to : 
    <br>Email : committee@eciawards.org
    <br>Period : February 13th to March 31st, 2020
    
    
    <br><br>
    Designed for Humanity,
    <br>Innovation for LOVE!
    <br><br>
    Hotline：
    <br>Dr.Owen Jia
    <br>Tel: +1(818) - 452 - 6959 
    <br><br>
    Email: Owen_jia@eciawards.org
    `,
    img: ['sucai/aUp/2020b_2.jpg'],
    titleimg: 'sucai/aUp/2020_2.jpg',
    time: '',
  },
  {
    id: 5,
    year: 2020,
    title: 'Become a Judge for ECI Awards 2020',
    desc: `Become a Judge for ECI Awards 2020
    ECI Awards is the only awards platform with background in investment, technology R&D/operations, as well as marketing. We gather experts from different industries to judge and critique innovative projects to set the benchmark for entrepreneurship, creativity and innovation. Now we are calling for nominations and self-recommendations for 2020 ECI Awards Jury which will be reviewed by ECI Awards Presidium and ECI Awards Organizing Committee. The final lineup of 2020 ECI Awards Jury will be announced later. The specific rules and procedures are shown as follows.
    <br><br>
    Rules of nomination and self-recommendation:
    <br>1. Chief supervisors and chief judges are invited by ECI Organizing Committee and normally self-recommendation for these roles will be dismissed.
    
    <br>
    <br>2.  We are looking for:
    <br>a. Decision makers of companies which are former ECI Awards winners.
    <br>b. Experts on investment with over 10 years of relevant experience, cases of investment in A shares or IPOs, or the role of managing funds of millions of U.S. dollars .
    <br>c. Experts on R&D from industry-leading tech companies. Owners of IPs are preferred.
    <br>d. Experts on marketing from industry-leading brands, media and agencies, who are decision makers of their companies and manage marketing expenses of millions of U.S. dollars annually.
    <br>e. Experts from research institute, academy or industry association with over 20 years of consulting experience.
    <br><br>
    Note: In order to ensure the impartiality and professionalism of ECI Awards, experts on investment will take up 20% of the 2020 ECI Awards Jury, experts on technology R&D 30%, experts on marketing 40%, and researchers 10%. ECI Awards Presidium and ECI Awards Organizing Committee reserve the right to optimize the jury lineup.
    <br><br>
    
    Selection procedures for 2020 ECI Awards Jury:
    <br>May 1, 2020:  call for nominations and self-recommendations opens
    <br>July 1, 2020:  announcement of chairs, chief supervisors and chief judges
    <br>July 15, 2020: call for nominations and self-recommendations closes
    <br>Date (TBA):  announcement of the final lineup of 2020ECI Awards
    
    
    <br><br>
    Submit application
    <br>https://jinshuju.net/f/czuUHz`,
    img: ['sucai/aUp/2020b.jpg'],
    titleimg: 'sucai/aUp/2020.jpg',
    time: '',
  },
  {
    id: 6,
    year: 2019,
    title: 'Become a Judge for the ECI Awards 2019',
    desc: `ECI welcomes senior executives from brands, platforms and service vendors as well as scholars and other experts in digital business to join our honorable jury. Judging experience in awarding programs is a plus.
    <br><br>
    We are looking for:
    <br>· Senior professionals with excellent abilities, strategies and visions to innovate business models in digital business.
    <br>· Senior professionals with contributions to product, technology, service, management and marketing innovations in digital business.
    <br>· Senior professionals with rich experiences and successful cases in investment and financing in digital business.
    <br>· Senior professionals with achievements in theoretical research of digital business.
    <br>· Heads of industry organizations with contributions to management and policy innovations in digital business.
    <br><br>
    Please contact us at lifei@eciawards.org to nominate a judge for the ECI Awards 2019. All nominations will be reviewed by the ECI Awards Advisory Committee to decide the jury lineup. The information will be kept in ECI’s data set even if your nomination does not lead to an official invitation to the jury this year.
    <br><br>
    Inquiries
    <br>Fei, International Affairs
    <br>Email: lifei@eciawards.org
    <br>Mobile: +86 18701591689
    <br><br>
    Co-branding
    <br>Seven Hu, Co-branding Director
    <br>Email: seven.hu@eciawards.org
    <br>Mobile: +86 18610999731
    <br>Direct: +86 10 58692387
    <br><br>
    Media Relations
    <br>Elliott Liu, Director of Media Communications
    <br>Email: elliott.liu@eciawards.org
    <br>Mobile: +86 13146153410`,
    img: ['sucai/aUp/2019b.jpg'],
    titleimg: 'sucai/aUp/2019.jpg',
    time: '',
  },
  {
    id: 7,
    year: 2018,
    title: '2018 ECI Awards Ceative Work Sharing Conference',
    desc: `The 2018 Maritime Silk Road ECI International Innovation Festival was held in Fengze District of Quanzhou City. The festival was jointly created by the Organizing Committee of ECI Awards and the people's government of Fengze District of Quanzhou City, focusing on "intelligent manufacturing, creative IP, industrial upgrading" and sharing new opportunities for future development. At the same time, the annual ECI Awards International Digital Business Innovation Award Award is also successfully unveiled here, in the presence of hundreds of participants in the joint witness, revealing the innovation-driven power.
    <br>
    On the morning of June 23, 2018, the second day of the International Digital Business Innovation Festival of the Maritime Silk Road commenced. As a part of the in-depth exchange and exhibition of this event, the "Technological Innovation Works", "Product Innovation Works", "Service Innovation Works" and "Marketing Innovation Works" were displayed here. The innovators from enterprises and the experts of ECI Awards evaluation joined the participants to innovate. Fruit exchange and sharing.
    <br>
    For ECI Awards, defining "innovation" is not just about helping innovators in the industry set benchmarks, but also about communicating in innovative ways of thinking. ECI will continue to create a good atmosphere for innovation and communication with innovative adherence and professional guidance.`,
    img: ['sucai/aUp/2018b.jpg'],
    titleimg: 'sucai/aUp/2018.png',
    time: '',
  },
  {
    id: 8,
    year: 2018,
    title: '2018 ECI Awards Final Jugde- Let the World See Your Innovation',
    desc: `Today, with the joint efforts of ECI experts and judges, the 2018 ECI Awards will be successfully concluded. ECI Awards, together with 75 innovative leaders at home and abroad, has conducted in-depth research and Discussion on 400 + selected works and selected the most representative innovative application works of all types. Innovative works from the United States, Japan, Russia, Korea, Australia, Nepal, Israel, Thailand, Italy, Ireland, India and other dozens of countries and regions jointly compete for this year's ECI Awards and the Grand Prize for Innovation.
    <br>
    2017 ECI Awards final jury jury photo
    <br>
    ECI jury: the strongest lineup & the most rigorous assessment
    <br>
    The ECI Final Review Committee of 2018 is composed of domestic and foreign business model designers, product experts, technical cafes, management experts, marketing masters and other digital business innovation leaders and applicants with professional background. They escort the impartiality and professionalism of the evaluation results with an international perspective, forward-looking insight and professional competence.
    <br>
    In addition, the 2018 ECI Awards adopts the network review + offline final review system, and scores fairly according to the ECI original "1 + 3" evaluation standard. Through continuous development and improvement, the evaluation process of ECI Awards has become more detailed, the evaluation rules have become more rigorous, and the nominated candidates for the grand prize have also been discussed from the various groups of gold awards. .
    <br>
    At the review meeting, President Li Xisha said that ECIAwards has carried "innovative" DNA since its birth. It gives inspiration to the industry by commending outstanding innovative works, linking resources and communicating innovative thinking. The best change is innovation. For digital commerce, a new round of change is beginning; for ECIAwards, each is a new beginning.
    <br><br>
    
    Dr. Jia introduced that as a service platform of innovation chain, ECI links the three links of brand, technology and capital, and uses marketing expertise to help technology and products to establish maximum brand value, so that more innovative works and innovative value can be seen on the platform of ECI.`,
    img: ['sucai/aUp/2018b_2.jpg'],
    titleimg: 'sucai/aUp/2018_2.jpg',
    time: '',
  },
];
